import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'trustsuite-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss'],
})
export class CompareDialogComponent {
  constructor(public dialogRef: MatDialogRef<CompareDialogComponent>) {}
}
