import { NgModule } from '@angular/core';
import { ApiModule, Configuration } from './api';
import { environment } from '@trustsuite/shared/frontend/environments';

@NgModule({
  imports: [
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.backend,
        })
    ),
  ],
  exports: [ApiModule],
})
export class SharedFrontendUserModule {}
