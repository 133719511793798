import { Injectable } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { SettingsApiService } from '@trustsuite/shared/frontend/user';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private settingsApiService: SettingsApiService) {}

  loadLanguage() {
    // TODO this will be difficult to load
    return firstValueFrom(
      this.settingsApiService.settingsControllerGetInformation()
    );
  }

  async changeLanguageSwitch(lang: MatSelectChange, loadLanguage: boolean) {
    // TODO when the user changes the url manually (form de to en), it will be saved in the database
    localStorage.setItem('language', lang.value);
    if (loadLanguage) {
      await firstValueFrom(
        this.settingsApiService.settingsControllerSetLanguage({
          language: lang.value,
        })
      );
    }
    location.reload();
  }
}
