import { Environment as Env } from '@trustsuite/shared/frontend/environments';
declare let window: any;

class Environment extends Env {
  constructor() {
    super();
    this.release = 'affa5c65f9cd06344f5f0a331768b92f5480b1db';
  }

  public get network() {
    return window.env.network;
  }

  public get logoTop() {
    return window.env.logoTop ?? this.logo;
  }
}

export const environment = new Environment();
