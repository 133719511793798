export * from './accounts.service';
import { AccountsApiService } from './accounts.service';
export * from './authorize.service';
import { AuthorizeApiService } from './authorize.service';
export * from './default.service';
import { DefaultApiService } from './default.service';
export * from './manager.service';
import { ManagerApiService } from './manager.service';
export * from './settings.service';
import { SettingsApiService } from './settings.service';
export * from './user.service';
import { UserApiService } from './user.service';
export const APIS = [AccountsApiService, AuthorizeApiService, DefaultApiService, ManagerApiService, SettingsApiService, UserApiService];
