import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimService } from './claim.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material/dialog';
import { VerificationSuccessComponent } from '../verification/verification-success/verification-success.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'trustsuite-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit {
  public claimType?: string;

  public data = '';
  public zoomFactor = 1;
  public zoomLevels: number[] = [];
  public selected = 100;
  public loaded = false;
  public mobile = false;
  public claimContent: HTMLElement | undefined;
  public drawerContent: HTMLElement | undefined;
  public toolBar: HTMLElement | undefined;
  public height = '100vh';

  constructor(
    private route: ActivatedRoute,
    public claimService: ClaimService,
    public appService: AppService,
    public breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.mobile = result.matches;
        if (this.mobile) {
          this.height = window.innerHeight - 113 + 'px';
        } else {
          this.height = '100vh';
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.claimService.dataTrusted = undefined;
    this.claimService.pdfSrc = undefined;
    const id = this.route.snapshot.params['id'];
    const key = decodeURI(location.hash.slice(1));
    if (!id) {
      return;
    }
    switch (this.route.snapshot.data['claimType']) {
      case 'sov':
        this.loadClaim(id, key);
        break;
      case 'pdf':
        await this.claimService.loadFile(id, key);
        break;
    }
  }

  private loadClaim(id: string, hash: string) {
    this.appService.claim = true;
    this.claimService.loadClaim(id, hash);
    this.changeZoomBehavior();
    for (let i = 10; i <= 100; ) {
      this.zoomLevels.push(i);
      i = i + 10;
    }
    // this.zoomLevels.push('Seitenbreite');
    // this.zoomLevels.push('Seitengröße');

    const checkElement = async (selector: any) => {
      while (document.querySelector(selector) === null) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
      }
      return document.querySelector(selector);
    };

    checkElement('#claimContent').then((selector) => {
      const element = document.querySelector<HTMLElement>('#claimContent');
      if (!element) return;
      element.style.width = '21cm';
      element.style.height = '0';
      this.zoomFactor = window.innerWidth / selector.offsetWidth;
      if (this.zoomFactor > 1) {
        this.zoomFactor = 1;
      }
      selector.style.transform = 'scale(' + this.zoomFactor + ')';
    });
  }

  async showCheck() {
    this.dialog.open(VerificationSuccessComponent, {
      maxHeight: '90vh',
      maxWidth: '100vw',
    });
  }

  getLogo() {
    return environment.logoTop;
  }

  changeZoomBehavior() {
    let scaling = false;

    document.body.addEventListener(
      'wheel',
      (e) => {
        if (e.ctrlKey) e.preventDefault(); //prevent zoom
      },
      { passive: false }
    );

    document.body.addEventListener(
      'keydown',
      (e) => {
        if (e.ctrlKey && e.key === '+') {
          e.preventDefault();
          this.zoom(true);
        }
        if (e.ctrlKey && e.key === '-') {
          e.preventDefault();
          this.zoom(false);
        }
      },
      { passive: false }
    );

    document.body.addEventListener(
      'touchstart',
      (e) => {
        if (e.touches.length === 2) {
          scaling = true;
        }
      },
      { passive: false }
    );

    let previousValue = 0;
    let pinchDifference = 0;

    document.body.addEventListener(
      'touchmove',
      (e) => {
        if (scaling) {
          e.preventDefault();
          const dist = Math.hypot(
            e.touches[0].pageX - e.touches[1].pageX,
            e.touches[0].pageY - e.touches[1].pageY
          );

          if (previousValue > dist) {
            pinchDifference = pinchDifference + (dist - previousValue);
            if (pinchDifference <= -20) {
              this.zoom(false);
              pinchDifference = 0;
            }
          } else if (previousValue < dist) {
            pinchDifference = pinchDifference + (dist - previousValue);
            if (pinchDifference >= 20) {
              this.zoom(true);
              pinchDifference = 0;
            }
          }
          previousValue = dist;
        }
      },
      { passive: false }
    );

    document.body.addEventListener(
      'touchend',
      () => {
        if (scaling) {
          scaling = false;
        }
      },
      { passive: false }
    );
  }

  show() {
    // TODO set correct width and height
    const win = window.open(
      '',
      'Certificate',
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=800'
    );
    if (!win) return;
    win.document.body.innerHTML = this.data;
  }

  zoom(zoomIn: boolean) {
    const claim = document.getElementById('claimContent');
    if (!claim) return;
    if (zoomIn) {
      if (this.zoomFactor < 1.0) {
        this.zoomFactor = this.zoomFactor + 0.1;
      }
    } else {
      // TODO zooming out when the claim already fits does not make any sense
      // Compare the transformed value with the screen size to find out if it already fits.
      if (this.zoomFactor > 0.1) {
        this.zoomFactor = this.zoomFactor - 0.1;
      }
    }

    claim.style.transform = 'scale(' + this.zoomFactor + ')';
  }

  setZoom(value: number) {
    const claim = document.getElementById('claimContent');
    if (!claim) return;
    this.zoomFactor = value / 100;
    claim.style.transform = 'scale(' + this.zoomFactor + ')';
  }
}
