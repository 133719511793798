import { Injectable } from '@angular/core';
import { ShortenApiService } from './api';
import { base58Decode, base58Encode } from '@trustcerts/helpers';
import { EncoderService } from '@trustsuite/shared/shared/url-shortener';
import { firstValueFrom } from 'rxjs';

/**
 * Shortes a url by upload the encrypted input on a server.
 */
@Injectable({
  providedIn: 'root',
})
export class ShortenerService {
  encoder: TextEncoder;
  decoder: TextDecoder;

  constructor(private api: ShortenApiService) {
    this.encoder = new TextEncoder();
    this.decoder = new TextDecoder();
  }

  /**
   * encrypt a string and uploads it to the service.
   * @param value
   * @returns id and password
   */
  public async shorten(value: string, email: string): Promise<string> {
    const encryption = await EncoderService.encode(this.encoder.encode(value));
    const id = await firstValueFrom(
      this.api.shortenControllerShorten({
        url: base58Encode(encryption.value),
        iv: encryption.iv,
        email,
      })
    ).then((res: any) => res.data.url);
    return `${id}#${encryption.key}`;
  }

  /**
   * Resolves a file where the identifier and the password is given.
   * @param id
   * @returns
   */
  public async resolve(id: string, key: string): Promise<string> {
    return firstValueFrom(this.api.shortenControllerResolve(id))
      .then((res) => {
        return EncoderService.decode({
          iv: res.iv,
          key,
          value: base58Decode(res.url),
        });
      })
      .then((decrypted: any) => this.decoder.decode(decrypted));
  }
}
