import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedFrontendStylingModule } from '@trustsuite/shared/frontend/styling';
import { LanguageService } from './language.service';
import { LanguageComponent } from './language/language.component';

@NgModule({
  imports: [CommonModule, SharedFrontendStylingModule],
  providers: [LanguageService],
  exports: [LanguageComponent],
  declarations: [LanguageComponent],
})
export class SharedFrontendLanguageModule {}
