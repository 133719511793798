import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShortenerService } from '@trustsuite/shared/frontend/url-shortener';

@Component({
  selector: 'trustsuite-short',
  templateUrl: './short.component.html',
  styleUrls: ['./short.component.scss'],
})
export class ShortComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shortenerService: ShortenerService
  ) {}

  ngOnInit(): void {
    const value = this.route.snapshot.params['id'];
    const key = this.route.snapshot.fragment;
    if (!key) return;
    this.shortenerService.resolve(value, key).then(
      (id: string) =>
        this.router.navigate([`claim/${id.split('/').slice(3).join('/')}`]),
      (err: any) => {
        alert('failed to decode url');
        console.log(err);
      }
    );
  }
}
