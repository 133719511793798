import { DynamicEnvironment } from './base-dynamic-environment';

export class Environment extends DynamicEnvironment {
  public production: boolean;
  public release: string;

  constructor() {
    super();
    this.production = true;
    this.release ='$SENTRY_RELEASE';
  }

  get version(): any {
    return 'VERSION';
  }
  get build(): any {
    return 'BUILD';
  }
}

export const environment = new Environment();
