import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@trustsuite/shared/frontend/environments';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ClaimService } from '../claim/claim.service';
import { FileService } from './file.service';

@Component({
  selector: 'trustsuite-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: any;
  public error?: boolean;
  public analyze = true;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public claimService: ClaimService
  ) {}

  ngOnInit(): void {
    this.claimService.hash = undefined;
    this.error = false;
    this.analyze = false;
    const encoding = this.route.snapshot.paramMap.get('encoding') ?? 'base58';
    const value =
      this.route.snapshot.paramMap.get('value') ??
      this.route.snapshot.paramMap.get('encoding');
    if (value) {
      this.fileService.verifyHash(encoding, value).then(
        (hash) => (this.claimService.hash = hash),
        () => (this.error = true)
      );
    }
  }

  getLogo() {
    return (environment.logo as string).startsWith('http')
      ? environment.logo
      : document.baseURI + environment.logo;
  }

  public verify(event: any) {
    const files = event.target.files;
    for (const file of files) {
      this.checkFile(file);
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.checkFile(file);
        });
      }
      // TODO how to handle folders?
    }
  }

  private async checkFile(file: File) {
    this.analyze = true;
    if (file.type === 'application/pdf') {
      await this.fileService.checkQrCode(file, true).catch(console.log);
    }
    this.fileService
      .verifyFile(file)
      .then(
        (hash) => this.claimService.loadClaimFromInput(hash),
        () => (this.error = true)
      )
      .then(() => (this.analyze = false));
  }

  reset() {
    this.claimService.hash = undefined;
    this.error = false;
  }
}
