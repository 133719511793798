import { Injectable } from '@angular/core';
import { ClaimVerifierService } from '@trustcerts/claim';
import { HttpClient } from '@angular/common/http';
import { DidHash, DidHashResolver } from '@trustcerts/did-hash';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DidIdResolver, DidId, DidNetworks } from '@trustcerts/did';
import { EncoderService } from '@trustsuite/shared/shared/url-shortener';
import { CompareDialogComponent } from '../compare-dialog/compare-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesApiService } from '@trustsuite/shared/frontend/file-encrypted';
export interface Issuer {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  pdfSrc?: Uint8Array;
  hash?: DidHash;
  dataTrusted?: any;
  data?: string;
  issuer?: Issuer;
  did?: DidId;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private fileFileSerivce: FilesApiService
  ) {
    DidNetworks.add('tc:dev', {
      gateways: ['https://api.gateway1.dev.trustcerts.de'],
      observers: ['https://api.observer1.dev.trustcerts.de'],
    });
    DidNetworks.add('tc:staging', {
      gateways: ['https://api.gateway1.staging.trustcerts.de'],
      observers: ['https://api.observer1.staging.trustcerts.de'],
    });
    DidNetworks.add('tc:prod', {
      gateways: ['https://api.gateway1.prod.trustcerts.de'],
      observers: ['https://api.observer1.prod.trustcerts.de'],
    });
  }

  private async getFile(
    id: string
  ): Promise<{ buffer: ArrayBuffer; iv: string }> {
    const res = await firstValueFrom(
      this.fileFileSerivce.filesControllerGetFileInformation(id)
    ).catch((e) => {
      console.log(e);
      // TODO redirect to a file to explain the error
      this.matSnackbar.open('file not found', undefined, { duration: 5000 });
      this.router.navigate(['/']);
    });

    if (!res) throw new Error('not found');
    const buffer = await firstValueFrom(
      res.url
        ? this.http.get(res.url, {
            responseType: 'blob',
          })
        : this.fileFileSerivce.filesControllerDownloadFile(id)
    );
    return {
      buffer: await buffer.arrayBuffer(),
      iv: res.iv,
    };
  }

  async loadFile(id: string, key: string) {
    if (!key) {
      alert('password is missing');
      return;
    }
    const res = await this.getFile(id);
    const resolver = new DidHashResolver();
    const decodedFile = await EncoderService.decode({
      value: new Uint8Array(res.buffer),
      key,
      iv: res.iv,
    });
    this.pdfSrc = decodedFile;
    this.hash = await resolver.verifyBuffer(decodedFile);
    this.resolveIssuer();
  }

  loadClaim(id: string, hash: string) {
    const claimVerifier = new ClaimVerifierService(`${location.origin}/claim`);
    claimVerifier.get(`${id}#${hash}`).then(
      async (claim) => {
        this.hash = claim.getValidation();
        this.resolveIssuer();
        claim.getHtml().then((value) => {
          this.dataTrusted = this.sanitizer.bypassSecurityTrustHtml(value);
          this.data = value;
          this.dialog.open(CompareDialogComponent);
        });
      },
      (err) => {
        console.log(err);
        this.router.navigate(['/error']);
      }
    );
  }

  loadClaimFromInput(hash: DidHash) {
    this.hash = hash;
    this.resolveIssuer();
  }

  async resolveIssuer() {
    if (this.hash) {
      try {
        this.did = await new DidIdResolver().load(
          this.hash.getControllers()[0],
          {
            time: this.hash.getMetaData().created,
          }
        );
        const service = this.did.getService('name');
        console.log(service.endpoint);
        this.issuer = await firstValueFrom(
          this.http.get<Issuer>(service.endpoint)
        );
      } catch (e) {
        console.log(e);
      }
    }
  }
}
