import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationSuccessComponent } from './verification-success/verification-success.component';
import { VerificationFailedComponent } from './verification-failed/verification-failed.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@trustsuite/shared/frontend/styling';
import { SharedFrontendLanguageModule } from '@trustsuite/shared/frontend/language';
@NgModule({
  declarations: [VerificationSuccessComponent, VerificationFailedComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedFrontendLanguageModule,
    RouterModule,
  ],
  exports: [VerificationSuccessComponent, VerificationFailedComponent],
})
export class VerificationModule {}
