<div [@.readonly]="disableAnimation">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 mat-dialog-title i18n>This document looks authentic!</h1>
    <button mat-icon-button (click)="openAll()" *ngIf="!opened">
      <mat-icon>unfold_more</mat-icon>
    </button>
    <button mat-icon-button (click)="closeAll()" *ngIf="opened">
      <mat-icon>unfold_less</mat-icon>
    </button>
  </div>

  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title i18n>File is unchanged</mat-panel-title>
        <mat-panel-description>
          {{ getHash().id.slice(0, 15) }}...
          <mat-icon>description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label i18n>Hash</mat-label>
        <input matInput readonly [value]="getHash().id" />
        <mat-icon matSuffix>fingerprint</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label i18n>Algorithm</mat-label>
        <input matInput readonly [value]="getHash().algorithm" />
        <mat-icon matSuffix>functions</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label i18n>Created at</mat-label>
        <input
          matInput
          readonly
          [value]="getHash().getMetaData().created | date: 'medium'"
        />
        <mat-icon matSuffix>schedule</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>DidDoc</mat-label>
        <textarea
          matInput
          readonly
          [value]="getHash().getDocument() | json"
          rows="10"
        ></textarea>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="getHash().revoked">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>Document is revoked</mat-panel-title>
        <mat-panel-description>
          {{ getHash().revoked | date }}
          <mat-icon>schedule</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label i18n>Created at</mat-label>
        <input
          matInput
          readonly
          [value]="getHash().getMetaData().created | date: 'medium'"
        />
        <mat-icon matSuffix>create</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label i18n>Revoked at</mat-label>
        <input matInput readonly [value]="getHash().revoked | date: 'medium'" />
        <mat-icon matSuffix>block</mat-icon>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Issuer is known</mat-panel-title>
        <mat-panel-description>
          {{ claimService.issuer?.name }}
          <mat-icon>person</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label>DID of the issuer</mat-label>
        <input
          matInput
          readonly
          [value]="getHash().getSignatures().values[0].identifier.split('#')[0]"
        />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Used key</mat-label>
        <input
          matInput
          readonly
          [value]="getHash().getSignatures().values[0].identifier"
        />
        <mat-icon matSuffix>key</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>DidDoc</mat-label>
        <textarea
          matInput
          readonly
          [value]="claimService.did?.getDocument() | json"
          rows="10"
        ></textarea>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title i18n>Issuance</mat-panel-title>
        <mat-panel-description>
          {{ getHash().getMetaData().created | date }}
          <mat-icon>schedule</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item>
          <mat-icon matListItemIcon>grid_view</mat-icon>
          <!-- <div mat-line>{{ hash.block.id }}</div> -->
          <!-- <div mat-line>Block</div> -->
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>

  <div mat-dialog-actions *ngIf="isDialog">
    <button mat-button mat-dialog-close i18n>Close</button>
  </div>
</div>
