import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { firstValueFrom } from 'rxjs';
import { AuthorizeApiService, User } from './api';

@Injectable({
  providedIn: 'root',
})
export class SharedFrontendUserService {
  public kcProfile?: KeycloakProfile;

  public user?: User;

  constructor(
    private keycloakService: KeycloakService,
    private authorizeApiService: AuthorizeApiService
  ) {
    this.keycloakService.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        this.keycloakService
          .loadUserProfile()
          .then((res) => (this.kcProfile = res));
      }
    });
  }

  init() {
    return firstValueFrom(this.authorizeApiService.authControllerInit()).then(
      (user) => (this.user = user)
    );
  }

  public async getInfo() {
    Sentry.setUser({ username: this.user?.username });
  }

  // cheks if a role is included in the jwt
  hasRole(key: string): boolean {
    return this.keycloakService.isUserInRole(key);
  }

  /**
   * Logs the user out.
   */
  logout() {
    this.keycloakService.logout();
  }
}
