import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { getCurrentLanguage } from '../setLanguage';

@Component({
  selector: 'trustsuite-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  @Input() loadConfig!: boolean;

  selected = 'en';

  constructor(public languageService: LanguageService) {
    this.selected = getCurrentLanguage();
  }
  ngOnInit(): void {
    if (this.loadConfig) {
      this.languageService.loadLanguage().then((res) => {
        if (res) {
          this.selected = res.language ? res.language : '';
        }
      });
    }
  }
}
