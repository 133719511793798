<mat-drawer-container *ngIf="claimService.hash" class="drawerContainer">
  <mat-drawer
    #drawer
    class="drawer"
    id="sideNav"
    mode="side"
    [opened]="!mobile"
  >
    <div fxLayout="column" id="sideContent">
      <div fxLayout="row" fxLayoutAlign="space-between center" id="sideTop">
        <img routerLink="/" [src]="getLogo()" id="logo" />
        <button mat-icon-button (click)="drawer.close()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <button
        mat-raised-button
        color="accent"
        (click)="showCheck()"
        id="checkButton"
        i18n
      >
        Display report
      </button>
      <mat-form-field appearance="fill">
        <mat-label i18n>Created at</mat-label>
        <input
          matInput
          disabled
          [value]="claimService.hash!.getMetaData().created | date: 'medium'"
        />
        <mat-icon matSuffix>create</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="claimService.hash!.revoked">
        <mat-label i18n>Revoked at</mat-label>
        <input
          matInput
          disabled
          [value]="claimService.hash!.revoked | date: 'medium'"
        />
        <mat-icon matSuffix>block</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label i18n>Issuer</mat-label>
        <input matInput disabled [value]="claimService.issuer?.name" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <div class="spacer"></div>
      <trustsuite-language [loadConfig]="false"></trustsuite-language>
      <footer>
        <a href="//www.trustcerts.de">Copyright by TrustCerts</a> |
        <a href="//www.trustcerts.de/impressum" i18n>Imprint</a>
      </footer>
    </div>
  </mat-drawer>
  <div *ngIf="claimService.dataTrusted" id="showClaim">
    <mat-toolbar class="drawerHeader">
      <mat-toolbar-row
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
      >
        <button mat-icon-button (click)="drawer.open()" *ngIf="!drawer.opened">
          <mat-icon>menu</mat-icon>
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="showCheck()"
          fxLFlex="70"
          fxHide.gt-xs
          id="checkButton"
          i18n
        >
          Display report
        </button>
        <div id="zoomBar" fxLayout="row" fxLayout="center">
          <mat-icon
            class="zoomIcon"
            (click)="zoom(false)"
            *ngIf="zoomFactor > 0.1; else noZoom2"
            >zoom_out</mat-icon
          >
          <ng-template #noZoom2
            ><mat-icon class="noZoom">zoom_out</mat-icon></ng-template
          >
          <mat-icon
            class="zoomIcon"
            (click)="zoom(true)"
            *ngIf="zoomFactor < 1.0; else noZoom"
            >zoom_in</mat-icon
          >
          <ng-template #noZoom
            ><mat-icon class="noZoom">zoom_in</mat-icon></ng-template
          >
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row fxLayout="row" fxLayoutGap="16px" fxHide.gt-xs>
        <div id="issuer" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>person</mat-icon
          ><span>{{ claimService.issuer?.name }}</span>
        </div>
        <div
          id="status"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="!claimService.hash!.revoked"
        >
          <mat-icon>check</mat-icon><span i18n>Valid</span>
        </div>
        <div
          id="status"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="claimService.hash!.revoked"
        >
          <mat-icon>block</mat-icon><span i18n>Invalid</span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div id="mainContent">
      <div id="claimContent">
        <div id="innerClaimContent" class="mat-elevation-z2">
          <div [innerHTML]="claimService.dataTrusted"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="claimService.pdfSrc">
    <mat-toolbar class="drawerHeader" fxHide.gt-xs>
      <mat-toolbar-row
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
      >
        <button mat-icon-button (click)="drawer.open()" *ngIf="!drawer.opened">
          <mat-icon>menu</mat-icon>
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="showCheck()"
          fxLFlex="70"
          fxHide.gt-xs
          id="checkButton"
          i18n
        >
          Show report
        </button>
      </mat-toolbar-row>
      <mat-toolbar-row fxLayout="row" fxLayoutGap="16px">
        <div id="issuer" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>person</mat-icon
          ><span>{{ claimService.issuer?.name }}</span>
        </div>
        <div
          id="status"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="!claimService.hash!.revoked"
        >
          <mat-icon>check</mat-icon><span i18n>Valid</span>
        </div>
        <div
          id="status"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="claimService.hash!.revoked"
        >
          <mat-icon>block</mat-icon><span i18n>Invalid</span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div id="mainContent">
      <ngx-extended-pdf-viewer
        fxFlex
        [src]="claimService.pdfSrc"
        [showToolbar]="true"
        [showSidebarButton]="false"
        [showFindButton]="false"
        [showPagingButtons]="true"
        [showZoomButtons]="true"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="false"
        [showDownloadButton]="false"
        [showBookmarkButton]="false"
        [showSecondaryToolbarButton]="true"
        [showRotateButton]="false"
        [showHandToolButton]="false"
        [showScrollingButton]="false"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
        [height]="height"
        [zoom]="'auto'"
      ></ngx-extended-pdf-viewer>
    </div>
  </div>
</mat-drawer-container>
