<mat-form-field>
  <mat-icon matPrefix>language</mat-icon>
  <mat-select
    [(value)]="selected"
    (selectionChange)="languageService.changeLanguageSwitch($event, loadConfig)"
  >
    <mat-option value="en" i18n>English</mat-option>
    <mat-option value="de" i18n>German</mat-option>
    <mat-option value="fr" i18n>French</mat-option>
  </mat-select>
</mat-form-field>
