import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortenerService } from './shortener.service';
import { ApiModule, Configuration } from './api';
import { environment } from '@trustsuite/shared/frontend/environments';

@NgModule({
  imports: [
    CommonModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.backend,
        })
    ),
  ],
  providers: [ShortenerService],
  exports: [ApiModule],
})
export class SharedFrontendUrlShortenerModule {}
