import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedFrontendLanguageModule } from '@trustsuite/shared/frontend/language';
import { SharedFrontendStylingModule } from '@trustsuite/shared/frontend/styling';
import { SharedFrontendTrackingModule } from '@trustsuite/shared/frontend/tracking';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { CenteredComponent } from './centered/centered.component';
import { ClaimComponent } from './claim/claim.component';
import { ShortComponent } from './claim/short/short.component';
import { CompareDialogComponent } from './compare-dialog/compare-dialog.component';
import { FileComponent } from './file/file.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { VerificationModule } from './verification/verification.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SharedFrontendUrlShortenerModule } from '@trustsuite/shared/frontend/url-shortener';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedFrontendPwaUpdateModule } from '@trustsuite/shared/frontend/pwa-update';
import { SharedFrontendFileEncryptedModule } from '@trustsuite/shared/frontend/file-encrypted';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedFrontendTrackingModule.forRoot(environment.release),
    SharedFrontendStylingModule,
    SharedFrontendLanguageModule,
    SharedFrontendUrlShortenerModule,
    SharedFrontendPwaUpdateModule,
    SharedFrontendFileEncryptedModule,
    NgxFileDropModule,
    VerificationModule,
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  declarations: [
    AppComponent,
    ClaimComponent,
    FileComponent,
    QrCodeComponent,
    ShortComponent,
    CenteredComponent,
    CompareDialogComponent,
  ],
  providers: [AppService],
  bootstrap: [AppComponent],
})
export class AppModule {}
