import { loadTranslations } from '@angular/localize';
import { LanguageFiles } from './languagesFiles';

export async function setLanguage() {
  // get the language from the localstorage
  const lang = getCurrentLanguage();
  // only load set the language if there is a value. If there is none, the default value (english) is used
  if (lang) {
    const res: LanguageFiles = await fetch(`/assets/locale/${lang}.json`).then(
      (res) => res.json()
    );
    loadTranslations(res.translations);
  }
}

/**
 * Returns the language if there is a match.
 * @returns
 */
export function getCurrentLanguage() {
  // get the language from the localstorage
  let lang = localStorage.getItem('language');
  // if not set, get the one from the browser settings
  if (!lang && /^de\b/.test(navigator.language)) {
    lang = 'de';
  }
  return lang ?? 'en';
}
