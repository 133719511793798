<div fxLayout="column" fxLayoutGap="16px">
  <mat-card appearance="outlined">
    <h2 i18n>We could not find an entry for this document</h2>
    <mat-card-content>
      <p i18n>
        We are not aware of this document. It may have been modified
        unintentionally.
      </p>
      <p i18n>Possible sources of error:</p>
      <ul>
        <li i18n>The exhibitor has published the wrong version.</li>
        <li i18n>
          The holder of the document has sent you the wrong version of the
          document.
        </li>
        <li>
          <span i18n>The file was accidentally changed</span>.:
          <ul>
            <li i18n>
              by "Print as PDF" by saving in a PDF editing program that has
              unprotected it.
            </li>
            <li i18n>by printing and scanning.</li>
          </ul>
        </li>
      </ul>
      <p i18n>
        If you have any questions, please contact the exhibitor noted on the
        document or contact our support.
      </p>
    </mat-card-content>
  </mat-card>
  <a routerLink="/" mat-raised-button color="accent" *ngIf="showLink" i18n>
    Check another file
  </a>
</div>
