declare let window: any;

export abstract class DynamicEnvironment {
  public get language(): string[] {
    return window['env']['language'] ?? ['en', 'de'];
  }

  public get backend() {
    return window['env']['backend'];
  }

  public get sentry() {
    return window['env']['sentry'];
  }

  public get logo() {
    return window['env']['logo'];
  }

  public get matomo() {
    return window['env']['matomo'];
  }

  public get keycloak() {
    return window['env']['keycloak'];
  }
}
