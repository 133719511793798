import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { DidIdResolver, DidId } from '@trustcerts/did';
import { DidHash } from '@trustcerts/did-hash';
import { ClaimService, Issuer } from '../../claim/claim.service';

export interface DialogSuccessData {
  hash: DidHash;
  issuer: DidId;
  issuerResolved: Issuer;
}
@Component({
  selector: 'trustsuite-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['./verification-success.component.scss'],
})
export class VerificationSuccessComponent implements AfterViewInit {
  public opened: boolean;

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  // Workaround for angular component issue #13870
  disableAnimation = true;

  @Input() isDialog = true;

  // TODO this component has to be designed to be opened as a popup or as an included html element. The dialog can be opened and then inject the html conent
  constructor(public claimService: ClaimService) {
    this.opened = false;
  }

  getHash(): DidHash {
    return this.claimService.hash as DidHash;
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  openAll() {
    this.accordion.openAll();
    this.opened = true;
  }

  closeAll() {
    this.accordion.closeAll();
    this.opened = false;
  }

  getIssuer(identifier: string, time: string) {
    return new DidIdResolver().load(identifier, { time });
  }
}
