import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AppService } from './app.service';
import { Identifier } from '@trustcerts/did';
import { environment } from '../environments/environment';

@Component({
  selector: 'trustsuite-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked, OnInit {
  constructor(
    public appService: AppService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    Identifier.setNetwork(environment.network);
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  getLogo() {
    return environment.logo;
  }
}
