import { ApplicationRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, interval, concat } from 'rxjs';

@NgModule({
  imports: [CommonModule],
})
export class SharedFrontendPwaUpdateModule {
  constructor(
    private updates: SwUpdate,
    private snackBar: MatSnackBar,
    private appRef: ApplicationRef
  ) {
    this.updateApp();
  }

  updateApp() {
    if (!this.updates.isEnabled) {
      console.log('Update function is not enabled');
      return;
    }
    const message = $localize`New update available.`;
    const button = $localize`Activate`;
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          this.snackBar
            .open(message, button)
            .onAction()
            .subscribe(() => {
              this.updates.activateUpdate().then(() => {
                document.location.reload();
              });
            });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });
    this.updates.unrecoverable.subscribe((event) => {
      alert(
        $localize`An error occurred that we cannot recover from: ${event.reason} Please reload the page.`
      );
    });
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everySixHours$ = interval(60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() =>
      this.updates.checkForUpdate()
    );
  }
}
