import { Injectable, NgZone } from '@angular/core';
import { base58Encode } from '@trustcerts/helpers';
import { DidHash, DidHashResolver } from '@trustcerts/did-hash';
import { getDocument, GlobalWorkerOptions, PDFPageProxy } from 'pdfjs-dist';
import QrScanner from 'qr-scanner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private sigResolver = new DidHashResolver();

  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone
  ) {
    GlobalWorkerOptions.workerSrc = './assets/pdf.worker.js';
    // QrScanner.WORKER_PATH = './assets/qr-scanner-worker.min.js';
  }

  async verifyFile(file: File): Promise<DidHash> {
    return this.sigResolver.verifyFile(file);
  }

  async verifyHash(encoding: string, hash: string): Promise<DidHash> {
    switch (encoding) {
      case 'hex':
        // eslint-disable-next-line no-case-declarations
        const match = hash.match(/[\da-f]{2}/gi);
        if (match) {
          const buffer = new Uint8Array(match.map((h) => parseInt(h, 16)));
          return this.sigResolver.verifyString(base58Encode(buffer));
        } else {
          throw new Error('wrong format');
        }
      case 'base58':
        return this.sigResolver.verifyString(hash);
      default:
        return Promise.reject();
    }
  }

  async checkQrCode(file: File, directOpen = false) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        if (!event.target) return;
        const content = new Uint8Array(event.target.result as ArrayBuffer);
        getDocument(content).promise.then(
          async (pdf) => {
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              await pdf
                .getPage(pageNumber)
                .then((page) => this.pagetoCanvas(page))
                .then((canvas) => {
                  QrScanner.scanImage(canvas, {
                    returnDetailedScanResult: true,
                  }).then(
                    (res) => {
                      const claimType = res.data.split('/').slice(-2)[0];
                      const claim = res.data.split('/').slice(-1)[0];
                      if (directOpen) {
                        this.ngZone.run(() =>
                          // TODO claim based on the route, claim when its an uploaded sovereign one
                          this.router
                            .navigate([claimType + '/' + claim.split('#')[0]], {
                              fragment: claim.split('#')[1],
                            })
                            .then()
                        );
                      } else {
                        this.snackbar
                          .open('Found QR-Code in document', 'Open')
                          .onAction()
                          .subscribe(() => {
                            this.router
                              .navigate(
                                [claimType + '/' + claim.split('#')[0]],
                                {
                                  fragment: claim.split('#')[1],
                                }
                              )
                              .then();
                          });
                      }
                    },
                    (err) => reject(err)
                  );
                });
            }
          },
          (err) => {
            console.log(err);
            reject();
          }
        );
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  private async pagetoCanvas(page: PDFPageProxy) {
    const scale = 2;
    const viewport = page.getViewport({ scale });
    // create canvas
    const id = 'canvas-id';
    const can = <HTMLCanvasElement>document.createElement('canvas');
    can.id = id;
    can.classList.add('invisible');
    document.body.appendChild(can);

    //
    // Prepare canvas using PDF page dimensions
    //
    const canvas = <HTMLCanvasElement>document.getElementById(id);
    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) throw Error('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    //
    // Render PDF page into canvas context
    //
    await page
      .render({
        canvasContext,
        viewport: viewport,
      })
      .promise.then();
    return canvas;
  }
}

