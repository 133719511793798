import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {
  NgxMatomoRouterModule,
  MatomoRouteDataInterceptor,
} from '@ngx-matomo/router';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from '@trustsuite/shared/frontend/environments';

function initializeAppFactory(): () => void {
  return () => {
    Sentry.init({
      release: environment.release,
      dsn: environment.sentry.dsn,
      environment: environment.sentry.env,
      enabled: environment.production,
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['localhost', environment.backend],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  };
}

// TODO split up in different modules and only import them when the config values are set
@NgModule({
  imports: [
    HttpClientModule,
    RouterModule.forRoot([]),
    CommonModule,
    NgxMatomoTrackerModule.forRoot({
      siteId: environment.matomo.siteId,
      trackerUrl: environment.matomo.url,
    }),
    NgxMatomoRouterModule.forRoot({
      // Declare built-in MatomoRouteDataInterceptor
      interceptors: [MatomoRouteDataInterceptor],
    }),
  ],
})
export class SharedFrontendTrackingModule {
  static forRoot(
    release: string
  ): ModuleWithProviders<SharedFrontendTrackingModule> {
    // save value in window to get it in sentry
    (window as any).release = release;
    return {
      ngModule: SharedFrontendTrackingModule,
      providers: [
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeAppFactory,
          deps: [Sentry.TraceService],
          multi: true,
        },
      ],
    };
  }
}
