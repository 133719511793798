import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'trustsuite-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent {
  @ViewChild('scanner', { static: false })
  // scanner!: ZXingScannerComponent;
  hasCamera?: boolean;
  hasPermission?: boolean;

  selectedDevice?: MediaDeviceInfo;
  availableDevices: MediaDeviceInfo[] = [];

  constructor(private router: Router) {}

  scanSuccessHandler(result: string) {
    const value = result.split('/').pop();
    this.router.navigate([`/claim/${value}`]);
  }

  camerasFoundHandler(foundDevices: MediaDeviceInfo[]) {
    console.log('Cameras found: ', foundDevices);
    this.availableDevices = foundDevices;
    this.hasCamera = true;
    // this.hasCamera = Boolean(foundDevices && foundDevices.length);
  }

  camerasNotFoundHandler() {
    this.hasCamera = false;
  }

  permissionFoundHandler(permission: boolean) {
    console.log('Permission Response: ', permission);
    this.hasPermission = permission;
  }
}
